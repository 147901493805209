// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payment-js": () => import("./../../../src/pages/payment.js" /* webpackChunkName: "component---src-pages-payment-js" */),
  "component---src-pages-products-comiket-js": () => import("./../../../src/pages/products/comiket.js" /* webpackChunkName: "component---src-pages-products-comiket-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-products-other-js": () => import("./../../../src/pages/products/other.js" /* webpackChunkName: "component---src-pages-products-other-js" */),
  "component---src-pages-products-weiss-js": () => import("./../../../src/pages/products/weiss.js" /* webpackChunkName: "component---src-pages-products-weiss-js" */),
  "component---src-templates-comiket-product-page-js": () => import("./../../../src/templates/ComiketProductPage.js" /* webpackChunkName: "component---src-templates-comiket-product-page-js" */),
  "component---src-templates-other-product-page-js": () => import("./../../../src/templates/OtherProductPage.js" /* webpackChunkName: "component---src-templates-other-product-page-js" */),
  "component---src-templates-weiss-product-page-js": () => import("./../../../src/templates/WeissProductPage.js" /* webpackChunkName: "component---src-templates-weiss-product-page-js" */)
}

